export const FETCH_RECRUIT_TOKEN = 'app/containers/IRRedirect/FETCH_RECRUIT_TOKEN';
export const UPDATE_RECRUIT_TOKEN = 'app/containers/IRRedirect/UPDATE_RECRUIT_TOKEN';
export const UPDATE_CUSTOM_TOKEN = 'app/containers/IRRedirect/UPDATE_CUSTOM_TOKEN';
export const FETCH_USER_DETAILS_COMPLETE = 'app/containers/IRRedirect/FETCH_USER_DETAILS_COMPLETE';
export const FETCH_MEETING_DETAILS_COMPLETE = 'app/containers/IRRedirect/FETCH_MEETING_DETAILS_COMPLETE';
export const INIT_ACTION = 'app/containers/IRRedirect/INIT_ACTION';
export const INIT_COMPLETE = 'app/containers/IRRedirect/INIT_COMPLETE';
export const JOIN_INTERVIEW_ROOMS = 'app/containers/IRRedirect/JOIN_INTERVIEW_ROOMS';
export const FETCH_ROOM_DETAILS = 'app/containers/IRRedirect/FETCH_ROOM_DETAILS';
export const FETCH_ROOM_DETAILS_COMPLETE = 'app/containers/IRRedirect/FETCH_ROOM_DETAILS_COMPLETE';
export const FETCH_RESUME_URL_COMPLETE = 'app/containers/IRRedirect/FETCH_RESUME_URL_COMPLETE';
export const SET_IR_PARENT = 'app/containers/IRRedirect/SET_IR_PARENT';
export const SET_MESSAGE_REF_DATA = 'app/containers/IRRedirect/SET_MESSAGE_REF_DATA';
export const SET_PRIVATE_MESSAGE_REF_DATA = 'app/containers/IRRedirect/SET_PRIVATE_MESSAGE_REF_DATA';
export const SET_SIGNAL_REF_DATA = 'app/containers/IRRedirect/SET_SIGNAL_REF_DATA';
export const SET_PARTICIPANT_REF_DATA = 'app/containers/IRRedirect/SET_PARTICIPANT_REF_DATA';
export const SET_CHAT_MESSAGE_DATA = 'app/containers/IRRedirect/SET_CHAT_MESSAGE_DATA';
export const SET_PRIVATE_CHAT_MESSAGE_DATA = 'app/containers/IRRedirect/SET_PRIVATE_CHAT_MESSAGE_DATA';
export const SET_SIGNAL_DATA = 'app/containers/IRRedirect/SET_SIGNAL_DATA';
export const SET_LOADING_TIME = 'app/containers/IRRedirect/SET_LOADING_TIME';
export const SET_PARTICIPANT_DATA = 'app/containers/IRRedirect/SET_PARTICIPANT_DATA';
export const SET_AUTH_COMPLETE = 'app/containers/IRRedirect/SET_AUTH_COMPLETE';
export const GET_CANDIDATE_DETAILS_COMPLETE = 'app/containers/IRRedirect/GET_CANDIDATE_DETAILS_COMPLETE';
export const GET_RECRUIT_CANDIDATE_DETAILS = 'app/containers/IRRedirect/GET_RECRUIT_CANDIDATE_DETAILS';
