import { uniqBy } from 'lodash';
import { USER_MEDIA_CHECK } from 'containers/App/constants';

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export async function getDeviceInfo() {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return {
    audioInputDevices: uniqBy(
      devices.filter(device => device.kind === 'audioinput'),
      'groupId',
    ),
    videoInputDevices: devices.filter(device => device.kind === 'videoinput'),
    audioOutputDevices: uniqBy(
      devices.filter(device => device.kind === 'audiooutput'),
      'groupId',
    ),
    hasAudioInputDevices: devices.some(device => device.kind === 'audioinput'),
    hasVideoInputDevices: devices.some(device => device.kind === 'videoinput'),
  };
}

// This function will return 'true' when the specified permission has been denied by the user, false if accepted
// If the API doesn't exist, or no permissions given then function returns an error, 'true' will be returned.
export async function isPermissionDenied(name) {
  try {
    const mediaCheck = USER_MEDIA_CHECK[name];
    if (!mediaCheck) return true;
    const mediaStream = await navigator?.mediaDevices?.getUserMedia(mediaCheck);
    const tracks = mediaStream?.getTracks();
    return tracks && tracks?.length < 1;
  } catch {
    return true;
  }
}

export async function getActiveDevices() {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    const audioTracks = stream.getAudioTracks();
    const videoTracks = stream.getVideoTracks();

    const activeAudioDevices = audioTracks.map(track => track.label);
    const activeVideoDevices = videoTracks.map(track => track.label);

    // Stop the media stream to release the resources, Failing to stop the tracks could lead to resource leaks, increased memory usage
    stream.getTracks().forEach(track => track.stop());

    return { activeAudioDevices, activeVideoDevices };
  } catch (error) {
    return null;
  }
}
