import {
  faCircleDot,
  faVideo,
  faCircleInfo,
  faWifiExclamation,
  faTriangleExclamation,
  faClock,
  faMessage,
  faBell,
  faUserSlash,
} from '@fortawesome/pro-regular-svg-icons';
import {
  virtualCamera,
  cameraSwitch,
  multipleFaces,
  noFaceDetected,
  microphoneDetected,
  microphoneSwitch,
  tabSwitchDark,
  tabSwitchLight,
} from 'images/index';

export const IR_SIGNAL_TYPES = {
  INTERACTION: 'INTERACTION',
  NOTIFICATION: 'NOTIFICATION',
  ALERT: 'ALERT',
};

export const IR_MESSAGE_TYPES = {
  VIDEO_REQUEST: 'VIDEO_REQUEST',
  VIDEO_REQUEST_DENIED: 'VIDEO_REQUEST_DENIED',
  JOINED_MEETING: 'JOINED_MEETING',
  LEAVE_MEETING: 'LEAVE_MEETING',
  VIRTUAL_CAMERA: 'VIRTUAL_CAMERA',
  MULTIPLE_CAMERA: 'MULTIPLE_CAMERA',
  CAMERA_SWITCH: 'CAMERA_SWITCH',
  RECORDING_STOPPED: 'RECORDING_STOPPED',
  MICROPHONE_SWITCH: 'MICROPHONE_SWITCH',
  MULTIPLE_MICROPHONE: 'MULTIPLE_MICROPHONE',
  NO_FACE_DETECTED: 'NO_FACE_DETECTED',
  MULTIPLE_FACES_DETECTED: 'MULTIPLE_FACES_DETECTED',
  POOR_INTERNET_CONNECTION: 'POOR_INTERNET_CONNECTION',
  BROWSER_CAMERA_PERMISSION_DENIED: 'BROWSER_CAMERA_PERMISSION_DENIED',
  SESSION_MIN_DURATION: 'SESSION_MIN_DURATION',
  OPEN_CODE_JAM: 'OPEN_CODE_JAM',
  OPEN_BOARD: 'OPEN_BOARD',
  TAB_SWITCH_DETECTED: 'TAB_SWITCH_DETECTED',
  TAB_SWITCH_DETECTED_THRESHOLD_TIME: 'TAB_SWITCH_DETECTED_THRESHOLD_TIME',
  NOTIFY_TO_JOIN: 'NOTIFY_TO_JOIN',
  PARTICIPANT_UNREACHABLE: 'PARTICIPANT_UNREACHABLE',
};

export const IR_SIGNAL_ICONS = {
  VIRTUAL_CAMERA: {
    dark: virtualCamera,
    light: virtualCamera,
  },
  MULTIPLE_CAMERA: {
    dark: virtualCamera,
    light: virtualCamera,
  },
  CAMERA_SWITCH: {
    dark: cameraSwitch,
    light: cameraSwitch,
  },
  MULTIPLE_FACES_DETECTED: {
    dark: multipleFaces,
    light: multipleFaces,
  },
  NO_FACE_DETECTED: {
    dark: noFaceDetected,
    light: noFaceDetected,
  },
  MULTIPLE_MICROPHONE: {
    dark: microphoneDetected,
    light: microphoneDetected,
  },
  MICROPHONE_SWITCH: {
    dark: microphoneSwitch,
    light: microphoneSwitch,
  },
  TAB_SWITCH_DETECTED: {
    dark: tabSwitchDark,
    light: tabSwitchLight,
  },
  TAB_SWITCH_DETECTED_THRESHOLD_TIME: {
    dark: tabSwitchDark,
    light: tabSwitchLight,
  },
  VIDEO_REQUEST_DENIED: faVideo,
  RECORDING_STOPPED: faCircleDot,
  DEFAULT: faCircleInfo,
  POOR_INTERNET_CONNECTION: faWifiExclamation,
  BROWSER_CAMERA_PERMISSION_DENIED: faTriangleExclamation,
  SESSION_ENDS_SOON: faTriangleExclamation,
  SESSION_MIN_DURATION: faClock,
  NEW_CHAT_MESSAGE: faMessage,
  NOTIFY_TO_JOIN: faBell,
  PARTICIPANT_UNREACHABLE: faUserSlash,
};

export const ALERT_TITLE = {
  VIRTUAL_CAMERA: 'Virtual camera detected',
  MULTIPLE_CAMERA: 'Multiple camera detected',
  CAMERA_SWITCH: 'Candidate switched camera',
  MULTIPLE_MICROPHONE: 'Multiple microphone detected',
  MICROPHONE_SWITCH: 'Switched audio device',
  MULTIPLE_FACES_DETECTED: 'Multiple faces detected',
  NO_FACE_DETECTED: 'Face not detected',
  TAB_SWITCH_DETECTED: 'Switched tab',
  TAB_SWITCH_DETECTED_THRESHOLD_TIME: 'Window focus out',
};

export const ALERT_SUB_TITLE = {
  VIRTUAL_CAMERA: "on candidate's device",
  MULTIPLE_CAMERA: "on candidate's device",
  NO_FACE_DETECTED: 'Candidate is not currently visible on the camera. Please check their position.',
  MULTIPLE_FACES_DETECTED: 'Heads up! The system has identified multiple individuals.',
  MULTIPLE_MICROPHONE: "on candidate's device",
  TAB_SWITCH_DETECTED: 'Candidate switched to a different tab',
  TAB_SWITCH_DETECTED_THRESHOLD_TIME: 'Candidate is out of interview window for over 30 seconds.',
};

export const INTERACTION_MODEL_TITLE = {
  'Video Requested': 'Turn on video',
};

export const INTERACTION_MODEL_CONTENT = {
  'Video Requested': 'Panel has requested to turn on your video',
};
