export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const CANDIDATE_ROLE = 'CANDIDATE';
export const EVALUATOR_ROLE = 'EVALUATOR';
export const PARENT_FRAME_CONTAINER = {
  RECRUIT: 'RECRUIT',
  TOP: 'TOP',
};
export const ROLE_MAP = {
  1: 'Candidate',
  2: 'Evaluator',
  3: 'Organizer',
  GUEST: 'Candidate',
  PARTICIPANT: 'Evaluator',
  ORGANIZER: 'Organizer',
};
export const ANONYMOUS = 'Anonymous';
