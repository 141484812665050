import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { ColorRing } from 'react-loader-spinner';
import { FEEDBACK } from 'components/IR/FeedBackPages/constants';

function Button(props) {
  const {
    leadingIcon,
    disabled,
    onClick,
    children,
    buttonClassname,
    tagline,
    tagLineTextColor,
    tailingIcon,
    isLoading,
    type,
    ...rest
  } = props;
  return (
    <button
      {...rest}
      type="button"
      className={classNames(buttonClassname || 'flex flex-col items-center justify-center', disabled && 'bg-gray-400')}
      onClick={onClick}
      disabled={disabled}
    >
      <div className="flex items-center gap-1 justify-center">
        {!isLoading && leadingIcon && leadingIcon}
        {!isLoading && children && <div className={classNames(leadingIcon && 'ml-1')}>{children}</div>}
      </div>
      {!isLoading && tailingIcon && tailingIcon}
      {isLoading && (
        <div className="colorRing h-full flex justify-center items-center ">
          <ColorRing
            visible
            height="30"
            width="30"
            ariaLabel="color-ring-loading"
            wrapperStyle={{}}
            wrapperClass="color-ring-loading"
            colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
          />
        </div>
      )}
      {tagline && (
        <p
          className={classNames(
            'text-sm font-medium leading-4 sm:flex justify-center',
            tagLineTextColor,
            type !== FEEDBACK && 'hidden',
          )}
        >
          {tagline}
        </p>
      )}
    </button>
  );
}
Button.propTypes = {
  leadingIcon: PropTypes.element,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  tagline: PropTypes.string,
  children: PropTypes.node,
  buttonClassname: PropTypes.string,
  tagLineTextColor: PropTypes.string,
  isLoading: PropTypes.bool,
};
export default Button;
