import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import TechnicalError from 'images/TechnicalError.svg';
import { getWindowObject } from 'containers/InterviewRooms/utils';
import { getItem, setItem } from 'utils/store';

function TechnicalErrorPage() {
  const meeting_details = getWindowObject('meeting_details') || {};
  const meetingId = get(meeting_details, 'meeting_id');
  const retryCount = getItem(`ir_retry_${meetingId}`) || 0;
  const [countDown, setCountDown] = useState(5 * (retryCount + 1));

  useEffect(() => {
    const timer = setInterval(() => {
      setCountDown(prevCounter => {
        if (prevCounter === 0) {
          setItem(`ir_retry_${meetingId}`, retryCount + 1);
          window.location.reload();
          clearInterval(timer);
          return prevCounter;
        }
        return prevCounter - 1;
      });
    }, 1000);
    if (retryCount >= 4) clearTimeout(timer);

    return () => clearTimeout(timer);
  }, []);
  return (
    <div className="w-screen h-screen flex flex-col justify-center items-center bg-white">
      <div className="dark:text-blue-600 text-blue-500 font-bold text-3xl mb-3">Something went wrong</div>
      {retryCount < 4 && (
        <div className="text-base font-normal dark:text-neutral-500 text-slate-800  text-center">
          Refreshing in {countDown} secs
        </div>
      )}
      <img src={TechnicalError} alt="Technical Error" className="my-4" />
      <div className="text-lg font-normal dark:text-neutral-500 text-slate-800 ">
        If the problem persists, please contact our{' '}
        <a
          href="https://talview.freshdesk.com/support/tickets/new"
          target="_blank"
          className="dark:text-blue-600 text-blue-500 font-medium"
        >
          support
        </a>{' '}
        team for assistance
      </div>
    </div>
  );
}

export default TechnicalErrorPage;
