import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFeatureValue } from '@growthbook/growthbook-react';
import { getItem } from 'utils/store';
import { growthBookFeatureFlags } from 'utils/featureFlags';
import { selectSignalDataList } from 'containers/IRRedirect/selectors';
import { setSignalDataAction } from 'containers/IRRedirect/actions';
import VideoServiceInterface from 'containers/IRRedirect/videoServiceInterface';
import { applyBackgroundEffect } from 'containers/InterviewRooms/utils';
import { providerOptions } from 'contexts/VideoProvider/constants';
import { effectsMap } from 'containers/InterviewRooms/constants';

export default function useLocalVideoToggle({ room, localTracks, getLocalVideoTrack, removeLocalVideoTrack, onError }) {
  const { background_images } = useFeatureValue(growthBookFeatureFlags.INTERVIEW_ROOMS_VIDEO_BACKGROUND_EFFECT) || {};
  const { irVideoServiceProvider } = VideoServiceInterface();
  const signalDataList = useSelector(selectSignalDataList);
  const isTwilio = irVideoServiceProvider === providerOptions.TWILIO;
  const localParticipant = room?.localParticipant;
  const isScreenTrack = track => track?.name?.includes('screen') || track?.source === 'screen_share';
  const videoTrack = localTracks?.find(track => track?.kind === 'video' && !isScreenTrack(track)) ?? null;

  const [isPublishing, setIsPublishing] = useState(false);
  const dispatch = useDispatch();
  const signalRef = useRef(signalDataList);

  useEffect(() => {
    signalRef.current = signalDataList;
  }, [signalDataList]);

  const onEnableVideoError = err => {
    if (err && err.toString().includes('Permission')) {
      dispatch(
        setSignalDataAction([
          ...signalRef.current,
          {
            signal_type: 'NOTIFICATION',
            signal_message:
              'Action needed: Camera control (on/off) is not working due to browser or system permissions. Please check and adjust your settings to enable full functionality',
            message_type: 'DEFAULT',
            notification_type: 'warning',
          },
        ]),
      );
    }
    if (onError) onError(err);
  };

  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setIsPublishing(true);
        getLocalVideoTrack()
          ?.then(async track => {
            if (!isTwilio) {
              const videoEffect = getItem('irVideoEffect');
              if (background_images?.includes(videoEffect) || videoEffect === effectsMap.BLUR) {
                await applyBackgroundEffect(track, videoEffect);
              }
            }
            await localParticipant?.publishTrack(track, { priority: 'high' });
          })
          .catch(onEnableVideoError)
          .finally(() => setIsPublishing(false));
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack]);

  return [!!videoTrack, toggleVideoEnabled];
}
