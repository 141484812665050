import {
  UPDATE_PARTICIPANT_STATUS,
  UPDATE_RECORDING_STATUS,
  FETCH_FACE_AUTH_DETAILS,
  CREATE_CANDIDATE_PROFILE,
  UPDATE_VIDEO_REQUEST_STATUS,
  CREATE_AUTH_REQUEST,
  CLOSE_SUBSCRIPTION_CHANNELS,
  UPDATE_RESUME_FULLSCREEN_STATUS,
  CONFIRM_LEAVE_MODAL_STATUS,
  ADD_IR_EVENT_LOG,
  UPLOAD_FILE,
  DOWNLOAD_FILE,
  UPDATE_FILES_DOWNLOAD_STATE,
  ADD_CHAT_INPUT_VAL_TO_MODAL,
  UPDATE_MODALS_OPEN_STATE,
  SUBMIT_FEEDBACK,
  GET_REGISTERED_USER,
  INVITE_REGISTERED_USER,
  UPDATE_FULL_VIEW_TABS_OPEN_STATE,
  UPDATE_SIDE_TABS_OPEN_STATE,
  SUBSCRIBE_PARTICIPANTS_DATA,
} from './constants';

export function updateParticipantStatusAction(status) {
  return {
    type: UPDATE_PARTICIPANT_STATUS,
    status,
  };
}

export function updateRecordingStatusAction(roomId, status) {
  return {
    type: UPDATE_RECORDING_STATUS,
    roomId,
    recordingStatus: status,
  };
}

export function fetchFaceAuthDetailsAction(candidateId) {
  return {
    type: FETCH_FACE_AUTH_DETAILS,
    candidateId,
  };
}

export function updateFullViewTabsOpenAction(fullViewTabsOpenState) {
  return {
    type: UPDATE_FULL_VIEW_TABS_OPEN_STATE,
    fullViewTabsOpenState,
  };
}

export function updateSideTabsOpenAction(sideTabsOpenState) {
  return {
    type: UPDATE_SIDE_TABS_OPEN_STATE,
    sideTabsOpenState,
  };
}

export function createCandidateProfileAction({ candidate_email, org_id, candidate_old_id }) {
  return {
    type: CREATE_CANDIDATE_PROFILE,
    candidate_email,
    org_id,
    candidate_old_id,
  };
}

export function createAuthRequestAction({ candidate_id, file, file_name, description }) {
  return {
    type: CREATE_AUTH_REQUEST,
    candidate_id,
    file,
    file_name,
    description,
  };
}

export function addIrEventLogAction(name) {
  return {
    type: ADD_IR_EVENT_LOG,
    name,
  };
}

export function submitFeedbackAction({ comment, model_id, rating, type, exitParentFrame }) {
  return {
    type: SUBMIT_FEEDBACK,
    feedbackData: {
      comment,
      model_id,
      rating,
      type,
    },
    exitParentFrame,
  };
}

export function updateVideoRequestStatusAction(requestStatus) {
  return {
    type: UPDATE_VIDEO_REQUEST_STATUS,
    requestStatus,
  };
}

export function closeSubscriptionsAction() {
  return {
    type: CLOSE_SUBSCRIPTION_CHANNELS,
  };
}

export function resumeFullscreenStatusAction(isResumeFullScreenOn) {
  return {
    type: UPDATE_RESUME_FULLSCREEN_STATUS,
    isResumeFullScreenOn,
  };
}

export function confirmLeaveModalAction(onConfirmLeaveModal, loading) {
  return {
    type: CONFIRM_LEAVE_MODAL_STATUS,
    onConfirmLeaveModal,
    loading,
  };
}

export function uploadFileAction({ file, fileName, fileType, fileSize, handleUpload, uploadError, uploadStatus }) {
  return {
    type: UPLOAD_FILE,
    file,
    fileName,
    fileType,
    fileSize,
    uploadToFirebase: handleUpload,
    uploadError,
    uploadStatus,
  };
}

export function downloadFile(fileId, fileName) {
  return {
    type: DOWNLOAD_FILE,
    fileId,
    fileName,
  };
}

export function updateFilesDownloadStateAction(filesDownloadState) {
  return {
    type: UPDATE_FILES_DOWNLOAD_STATE,
    filesDownloadState,
  };
}

export function addChatInputValToModalAction(chatInputValues) {
  return {
    type: ADD_CHAT_INPUT_VAL_TO_MODAL,
    chatInputValues,
  };
}

export function updateModalsOpenStateAction(modalsOpenState) {
  return {
    type: UPDATE_MODALS_OPEN_STATE,
    modalsOpenState,
  };
}
export function getRegisteredUserAction(value) {
  return {
    type: GET_REGISTERED_USER,
    searchValue: value,
  };
}

export function inviteRegisteredUserAction(email, roomId) {
  return {
    type: INVITE_REGISTERED_USER,
    email,
    room_id: roomId,
  };
}

export function subscribeParticipantsDataAction(meetingId) {
  return {
    type: SUBSCRIBE_PARTICIPANTS_DATA,
    meetingId,
  };
}
