export const growthBookFeatureFlags = {
  MIX_PANEL_ENABLED: 'mixpanel_enabled',
  INTERVIEW_ROOMS_CANDIDATE_INMEETING_FACE_AUTH: 'ir_inmeeting_authentication',
  INTERVIEW_ROOMS_CODE_JAM_ENABLED: 'ir_code_jam',
  INTERVIEW_ROOMS_DOWNLOAD_RESUME: 'ir_resume_download',
  INTERVIEW_ROOMS_INTERVIEW_BUILDER: 'ir_interview_builder',
  INTERVIEW_ROOMS_FEEDBACK_ENABLED: 'ir_feedback',
  INTERVIEW_ROOMS_WHITEBOARD: 'ir_whiteboard',
  INTERVIEW_ROOM_THEME_SWITCHER: 'ir_theme_switcher',
  INTERVIEW_ROOMS_ENABLE_EVALUATION: 'ir_evaluation',
  INTERVIEW_ROOMS_VIDEO_BACKGROUND_EFFECT: 'ir_video_background_image_url',
  INTERVIEW_ROOMS_MIN_DURATION: 'ir_interview_min_duration_notify_time',
  INTERVIEW_ROOMS_CHAT_LINK: 'ir_chat_link',
  INTERVIEW_ROOMS_CHAT_CODE_BLOCK: 'ir_chat_code_block',
  INTERVIEW_ROOMS_CANDIDATE_FACE_DETECT_NOTIFICATION: 'ir_inmeeting_auth_face_detection',
  NOICE_CANCELLATION: 'ir_noise_cancellation',
  INTERVIEW_ROOMS_DETECT_WINDOW_FOCUS_OUT: 'ir_window_focus_out_detect',
  IR_VIDEO_VP9: 'ir_video_vp9',
  IR_VIDEO_CAPTURE_DEFAULT_RESOLUTION: 'ir_video_capture_default_resolution',
  INTERVIEW_ROOMS_PARTICIPANT_INVITE: 'ir_participant_invite',
  IR_PARTICIPANT_JOINING_LATE_NOTIFY: 'ir_participant_joining_late_notify',
  IR_CODE_JAM_ADD_QUESTIONS: 'ir_code_jam_add_questions',
};
