/* eslint-disable no-unused-expressions */
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSignalDataAction } from 'containers/IRRedirect/actions';
import VideoServiceInterface from 'containers/IRRedirect/videoServiceInterface';
import { selectSignalDataList } from 'containers/IRRedirect/selectors';
import { providerOptions } from 'contexts/VideoProvider/constants';
import useIsTrackEnabled from './useIsTrackEnabled';

export default function useLocalAudioToggle({ localAudioTrack }) {
  const { irVideoServiceProvider } = VideoServiceInterface();
  const signalDataList = useSelector(selectSignalDataList);
  const isTwilio = irVideoServiceProvider === providerOptions.TWILIO;
  const audioTrack = localAudioTrack;
  const isEnabled = useIsTrackEnabled(audioTrack);
  const dispatch = useDispatch();

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      if (!isTwilio) {
        audioTrack?.isMuted ? audioTrack?.unmute() : audioTrack?.mute();
      } else {
        audioTrack?.isEnabled ? audioTrack?.disable() : audioTrack?.enable();
      }
    } else {
      dispatch(
        setSignalDataAction([
          ...signalDataList,
          {
            signal_type: 'NOTIFICATION',
            signal_message:
              'Action needed: Mic control (mute/unmute) is not working due to browser or system permissions. Please check and adjust your settings to enable full functionality',
            message_type: 'DEFAULT',
            notification_type: 'warning',
          },
        ]),
      );
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled];
}
