import produce from 'immer';
import {
  TOGGLE_PARTICIPANT_PANEL,
  FETCH_FACE_AUTH_DETAILS,
  FETCH_FACE_AUTH_DETAILS_COMPLETE,
  UPDATE_VIDEO_REQUEST_STATUS,
  CREATE_CANDIDATE_PROFILE_COMPLETE,
  CONFIRM_LEAVE_MODAL_STATUS,
  UPLOAD_FILE,
  UPLOAD_FILE_COMPLETE,
  statusMap,
  UPDATE_FILES_DOWNLOAD_STATE,
  ADD_CHAT_INPUT_VAL_TO_MODAL,
  UPDATE_MODALS_OPEN_STATE,
  SUBMIT_FEEDBACK_COMPLETED,
  GET_REGISTERED_USER,
  GET_REGISTERED_USER_COMPLETED,
  INVITE_REGISTERED_USER_COMPLETED,
  INVITE_REGISTERED_USER,
  UPDATE_FULL_VIEW_TABS_OPEN_STATE,
  UPDATE_SIDE_TABS_OPEN_STATE,
} from './constants';

export const initialState = {
  loading: true,
  isParticipantsTabOpen: false,
  recordingState: { success: null, error: null, recordingStatus: false },
  authDetails: { authImageDetails: [], externalId: null, error: null, isLoading: false },
  evaluationDetails: {
    success: null,
    error: null,
    isLoading: false,
    saveLoading: false,
    payload: {},
    recommendation: null,
    evaluation: null,
    step: {},
  },
  sideTabsOpenState: {
    isChatTabOpen: false,
    isProfileTabOpen: false,
    isParticipantsTabOpen: false,
    isGuideTabOpen: false,
    isDescriptionTabOpen: false,
  },
  fullViewTabsOpenState: {
    isCodingTabOpen: false,
    isWhiteboardTabOpen: false,
    isEvaluationTabOpen: false,
    isResumeTabOpen: false,
  },
  modalsOpenState: {
    isChatLinkModalOpen: false,
    isInvitePeopleModalOpen: false,
  },
  isVideoRequestDenied: false,
  cameraDevice: '',
  newCandidateProfile: {
    success: false,
    candidate_id: null,
  },
  onConfirmLeaveModal: false,
  fileAttachState: {
    file: null,
    fileId: null,
    fileName: null,
    fileType: null,
    fileSize: null,
    uploadError: null,
    uploadStatus: null,
  },
  fileDownloadState: {
    downloadError: null,
    downloadStatus: null,
    fileUrl: null,
    id: null,
  },
  filesDownloadState: [],
  chatInputValues: {
    chatText: '',
    chatUrl: '',
  },
  feedbackSubmitted: false,
  registeredUserData: {
    data: {},
    isLoading: false,
    error: null,
  },
  inviteRegisteredUser: {
    loadingStates: {},
    successStates: {},
    errorStates: {},
  },
};

/* eslint-disable default-case, no-param-reassign */
const interviewRoomsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CREATE_CANDIDATE_PROFILE_COMPLETE:
        draft.newCandidateProfile = action.newCandidateProfile;
        break;
      case TOGGLE_PARTICIPANT_PANEL:
        draft.isParticipantsTabOpen = !draft.isParticipantsTabOpen;
        break;
      case FETCH_FACE_AUTH_DETAILS:
        draft.authDetails.authImageDetails = [];
        draft.authDetails.error = null;
        draft.authDetails.isLoading = true;
        break;
      case FETCH_FACE_AUTH_DETAILS_COMPLETE:
        draft.authDetails.authImageDetails = action.authDetails.authImageDetails;
        draft.authDetails.externalId = action.authDetails.externalId;
        draft.authDetails.error = action.authDetails.error;
        draft.authDetails.isLoading = action.authDetails.isLoading;
        break;
      case UPDATE_FULL_VIEW_TABS_OPEN_STATE:
        draft.fullViewTabsOpenState.isEvaluationTabOpen = action.fullViewTabsOpenState.isEvaluationTabOpen || false;
        draft.fullViewTabsOpenState.isCodingTabOpen = action.fullViewTabsOpenState.isCodingTabOpen || false;
        draft.fullViewTabsOpenState.isWhiteboardTabOpen = action.fullViewTabsOpenState.isWhiteboardTabOpen || false;
        draft.fullViewTabsOpenState.isResumeTabOpen = action.fullViewTabsOpenState.isResumeTabOpen || false;
        break;
      case UPDATE_SIDE_TABS_OPEN_STATE:
        draft.sideTabsOpenState.isChatTabOpen = action.sideTabsOpenState.isChatTabOpen || false;
        draft.sideTabsOpenState.isProfileTabOpen = action.sideTabsOpenState.isProfileTabOpen || false;
        draft.sideTabsOpenState.isParticipantsTabOpen = action.sideTabsOpenState.isParticipantsTabOpen || false;
        draft.sideTabsOpenState.isGuideTabOpen = action.sideTabsOpenState.isGuideTabOpen || false;
        draft.sideTabsOpenState.isDescriptionTabOpen = action.sideTabsOpenState.isDescriptionTabOpen || false;
        break;
      case UPDATE_VIDEO_REQUEST_STATUS:
        draft.isVideoRequestDenied = action.requestStatus;
        break;
      case CONFIRM_LEAVE_MODAL_STATUS:
        draft.onConfirmLeaveModal = action.onConfirmLeaveModal;
        draft.loading = false;
        break;
      case UPLOAD_FILE:
        draft.fileAttachState.uploadError = action.uploadError || null;
        draft.fileAttachState.uploadStatus = action.uploadStatus || statusMap.IN_PROGRESS;
        draft.fileAttachState.fileName = action.fileName;
        draft.fileAttachState.fileType = action.fileType;
        draft.fileAttachState.fileSize = action.fileSize;
        draft.fileAttachState.file = action.file;
        break;
      case UPLOAD_FILE_COMPLETE:
        draft.fileAttachState.uploadError = action.uploadError;
        draft.fileAttachState.uploadStatus = action.uploadStatus;
        draft.fileAttachState.fileId = action.fileId;
        break;
      case UPDATE_FILES_DOWNLOAD_STATE:
        draft.filesDownloadState = action.filesDownloadState;
        break;
      case ADD_CHAT_INPUT_VAL_TO_MODAL:
        draft.chatInputValues.chatText = action.chatInputValues.chatText;
        draft.chatInputValues.chatUrl = action.chatInputValues.chatUrl;
        break;
      case UPDATE_MODALS_OPEN_STATE:
        draft.modalsOpenState.isChatLinkModalOpen = action.modalsOpenState.isChatLinkModalOpen || false;
        draft.modalsOpenState.isInvitePeopleModalOpen = action.modalsOpenState.isInvitePeopleModalOpen || false;
        break;
      case SUBMIT_FEEDBACK_COMPLETED:
        draft.feedbackSubmitted = action.feedbackSubmitted;
        break;
      case GET_REGISTERED_USER:
        draft.registeredUserData.data = {};
        draft.registeredUserData.isLoading = true;
        draft.registeredUserData.error = null;
        break;
      case GET_REGISTERED_USER_COMPLETED:
        draft.registeredUserData.data = action.data || {};
        draft.registeredUserData.isLoading = false;
        draft.registeredUserData.error = action.error || null;
        break;
      case INVITE_REGISTERED_USER:
        draft.inviteRegisteredUser.loadingStates[action.email] = true;
        draft.inviteRegisteredUser.successStates[action.email] = null;
        draft.inviteRegisteredUser.errorStates[action.email] = null;
        break;
      case INVITE_REGISTERED_USER_COMPLETED:
        draft.inviteRegisteredUser.loadingStates[action.email] = false;
        draft.inviteRegisteredUser.successStates[action.email] = action.success || null;
        draft.inviteRegisteredUser.errorStates[action.email] = action.error || null;
        break;
    }
  });

export default interviewRoomsReducer;
