import React from 'react';
import VideoProvider from 'contexts/VideoProvider';
import InterviewRoomsRedirect from 'containers/IRRedirect/Loadable';
import IRMobile from 'containers/IRMobile';
import TechnicalErrorPage from 'components/IR/TechnicalErrorPage';
import InterviewRooms from 'containers/InterviewRooms/Loadable';
import RecordingPlayback from 'containers/RecordingPlayback/Loadable';
import InterviewInsights from 'containers/InterviewInsights/Loadable';

const routes = () => [
  {
    path: '/ir/redirect/*',
    element: (
      <VideoProvider>
        <InterviewRoomsRedirect />
      </VideoProvider>
    ),
  },
  {
    path: '/ir/error',
    element: <TechnicalErrorPage />,
  },
  {
    path: '/mobile',
    element: (
      <VideoProvider>
        <IRMobile />
      </VideoProvider>
    ),
  },
  {
    path: '/interview-rooms/*',
    element: (
      <VideoProvider>
        <InterviewRooms />
      </VideoProvider>
    ),
  },
  {
    path: '/playback',
    element: <RecordingPlayback />,
  },
  {
    path: '/insights',
    element: <InterviewInsights />,
  },
];
export default routes;
