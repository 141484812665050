import { createSelector } from 'reselect';
import selectInterviewRedirect from 'containers/IRRedirect/selectors';
import { initialState } from './reducer';

export const selectInterviewRoomsDomain = state => state?.interviewRooms || initialState;

export const makeInterviewRooms = () => createSelector(selectInterviewRoomsDomain, subState => subState);
export const selectVideoRequestState = createSelector(
  selectInterviewRoomsDomain,
  subState => subState?.isVideoRequestDenied,
);
export const selectFullViewTabsOpenState = createSelector(
  selectInterviewRoomsDomain,
  subState => subState?.fullViewTabsOpenState,
);
export const selectSideTabsOpenState = createSelector(
  selectInterviewRoomsDomain,
  subState => subState?.sideTabsOpenState,
);

export const selectChatInputValues = createSelector(selectInterviewRoomsDomain, subState => subState?.chatInputValues);

export const selectModalOpenState = createSelector(selectInterviewRoomsDomain, subState => subState?.modalsOpenState);

export const selectIsCandidate = createSelector(selectInterviewRedirect, subState => subState?.isCandidate);
export const selectMeetingDetailsState = createSelector(selectInterviewRedirect, subState => subState?.meetingDetails);
export const participantDataState = createSelector(selectInterviewRedirect, subState => subState?.participantData);
export const authUserDataState = createSelector(selectInterviewRedirect, subState => subState?.authUserData);
